import React, { useState } from "react"
import styled, { keyframes } from "styled-components"
import { graphql, Link, navigate } from "gatsby"
import PropTypes from "prop-types"

import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"
import { useDispatch } from "react-redux"

import { notify } from "../state"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import PageHeader from "../components/elements/PageHeader"
import Label from "../components/elements/label"

import media from "../styles/media"

import placeholder from "../images/icons/profile.svg"
import underline from "../images/Auth/underline_three.png"
import Whatsapp from "../images/social/whatsapp.svg"
import Twitter from "../images/social/twitter.svg"
import Facebook from "../images/social/facebook.svg"
// import Instagram from "../images/social/instagram.svg"
import Linkedin from "../images/social/linkedin.svg"

const Container = styled.div`
  background-color: rgba(196, 18, 48, 0.99);
  padding-left: var(--gutter-s);
  padding-bottom: 10vh;
  h1 {
    font-size: 4rem;
  }
  ${media.tablet`
    padding-left: 10vw;
  `}
  ${media.laptop`
    padding-left: 25vw;
  `}
`

const Picture = styled.div`
  margin-top: 4rem;
  img {
    object-fit: cover;
    display: inline-block;
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }
`

const Name = styled.div`
  margin-bottom: 0.5rem;
  span {
    margin-left: 10px;
  }
`

const Votes = styled.div`
  margin-bottom: 3rem;
`

const InfoBlock = styled.div`
  margin-bottom: 2rem;
  padding-right: var(--gutter-s);
  p {
    margin-top: 0.5rem;
    font-size: 1.1rem;
    line-height: 2rem;
  }
  ${media.tablet`
    padding-right: var(--gutter-l);
  `}
`

const MetaPanel = styled.div`
  font-family: "AvenirLTStdBlack";
  font-size: 0.9rem;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.8);
  padding: 3rem 0;
  a {
    display: inline-block;
    background-image: url(${underline});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: bottom left;
    padding: 0.5rem;
    margin-right: 2rem;
  }
`

const VoteButton = styled.div`
  display: inline-flex;
  position: relative;
  margin-right: 2rem;
  z-index: 1;
  padding: 0.75rem 2.5rem 0.75rem 1rem;
  margin-bottom: 2em;
  font-size: 1.5rem;
  &:after {
    content: "";
    position: absolute;
    background-color: rgba(255, 255, 255, 0.95);
    width: 100%;
    transform: rotate(-1deg);
    height: 100%;
    left: 0;
    top: 0;
    z-index: -1;
  }
  ${media.tablet`
    margin-top: 0;
  `}
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  position: absolute;
  right: 0;
  top: 40%;
  transform: translate(-80%, -50%);
  width: 16px;
  height: 16px;
  svg {
    width: 16px;
    height: 16px;
    transform-origin: 50% 50%;
    animation: ${rotate} 2s linear infinite;
    fill: rgba(0, 0, 0, 0.8);
  }
`

const ButtonsDiv = styled.div`
  display: inline-block;
  text-align: center;
  ${media.tablet`
    text-align: left;
  `}
`

const SocialShare = styled.div`
  padding-top: 2em;
  text-align: center;
  img {
    width: 35px;
    height: 35px;
    margin-right: 1em;
    opacity: 0.8;
  }
`

const Loader = () => {
  return (
    <Spinner>
      <svg viewBox="0 0 24 24">
        <path d="M0 11c.511-6.158 5.685-11 12-11s11.489 4.842 12 11h-2.009c-.506-5.046-4.793-9-9.991-9s-9.485 3.954-9.991 9h-2.009zm21.991 2c-.506 5.046-4.793 9-9.991 9s-9.485-3.954-9.991-9h-2.009c.511 6.158 5.685 11 12 11s11.489-4.842 12-11h-2.009z" />
      </svg>
    </Spinner>
  )
}

const ProfileDetail = ({ pageContext }) => {
  const dispatch = useDispatch()

  const profile = pageContext.profile

  const [voting, setVoting] = useState(false)
  const [vote] = useMutation(VOTE_MUTATION)

  const menuItems = [{ name: "Leaderboard", path: "/leaderboard" }]

  const onImgError = e => {
    const target = e.target
    target.src = `${placeholder}`
  }

  const handleVote = async () => {
    setVoting(true)
    try {
      const id = profile.id
      const result = await vote({
        variables: {
          id,
        },
      })
      if (result.data.vote.__typename === "ConflictError") {
        dispatch(
          notify({
            message: result.data.vote.message,
            variant: "error",
          })
        )
      } else {
        dispatch(
          notify({
            message: "Thank you for voting!",
            variant: "success",
          })
        )
      }
      setVoting(false)
    } catch (err) {
      dispatch(
        notify({
          message: "You need to be signed in to vote.",
          variant: "error",
        })
      )
      setVoting(false)
      window.setTimeout(() => {
        navigate("/sign-up")
      }, 500)
    }
  }

  return (
    <>
      <SEO title={`${profile.givenName}`} />
      <Navbar back isGHP items={menuItems} topOffset={0} bottomOffset={125} />
      <Container>
        <PageHeader leftIndent />
        <Picture>
          <img
            src={encodeURI(
              `${process.env.S3_BUCKET_BASE_URL}${profile.img}/${profile.id}.jpg`
            )}
            alt={`Sound Idea Sessions | ${profile.givenName}`}
            onError={onImgError}
          />
        </Picture>
        <Name>
          <Label
            fontSize={1}
            fontScaled
            outerRotate={-1}
            innerRotate={0}
            value={`${profile.givenName} ${profile.familyName}`}
          />
          <span> from {profile.city} </span>
        </Name>
        <Votes>
          <Label
            fontSize={1}
            fontScaled
            outerRotate={-1}
            innerRotate={0}
            value={`✪ ${profile.voteCount} votes`}
          />
        </Votes>
        <InfoBlock>
          <Label
            fontSize={1}
            fontScaled
            outerRotate={-1}
            innerRotate={0}
            value="About me"
          />
          <p> {profile.biography} </p>
        </InfoBlock>
        <InfoBlock>
          <Label
            fontSize={1}
            fontScaled
            outerRotate={-1}
            innerRotate={0}
            value="Why you should vote for me"
          />
          <p> {profile.motivation} </p>
        </InfoBlock>
        <ButtonsDiv>
          <MetaPanel>
            <VoteButton onClick={handleVote} className="mouse-link">
              <span> VOTE FOR ME </span>
              {voting && <Loader />}
            </VoteButton>
            <Link className="mouse-link" to="/leaderboard">
              View Leaderboard
            </Link>
            <Link className="mouse-link" to="/profiles">
              View All Profiles
            </Link>
          </MetaPanel>
          <SocialShare
            data-href={`https://www.soundideasessions.com/profiles/${profile.id}/`}
            data-layout="button"
            data-size="small"
          >
            <a
              className="mouse-link"
              href={`whatsapp://send?text=https://www.soundideasessions.com/profiles/${profile.id}/`}
              data-action="share/whatsapp/share"
            >
              <img src={Whatsapp} />
            </a>
            <a
              className="mouse-link"
              href={`http://www.twitter.com/share?text=Vote for my Growth Hacker Profile! #soundideasessions`}
              data-show-count="false"
              target="blank"
            >
              <img src={Twitter} />
            </a>
            <a
              target="blank"
              href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.soundideasessions.com%2Fprofiles%2F${profile.id}%2F&amp;src=sdkpreparse`}
              className="fb-xfbml-parse-ignore mouse-link"
            >
              <img src={Facebook} />
            </a>
            {/* <a>
              <img src={Instagram} />
            </a> */}
            <a
              className="mouse-link"
              href={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.soundideasessions.com/profiles/&title=Vote&source=https://www.soundideasessions.com/profiles/${profile.id}/`}
              target="blank"
            >
              <img src={Linkedin} />
            </a>
          </SocialShare>
        </ButtonsDiv>
      </Container>
      <Footer back />
    </>
  )
}

ProfileDetail.propTypes = {
  pageContext: PropTypes.object,
}

export default ProfileDetail

const VOTE_MUTATION = gql`
  mutation Vote($id: ID!) {
    vote(input: { profileId: $id }) {
      __typename
      ... on VoteSuccess {
        vote {
          id
        }
      }
      ... on Error {
        message
      }
    }
  }
`
